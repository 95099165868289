var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-full" }, [
    _c("div", { staticClass: "inline-block whitespace-nowrap" }, [
      _c(
        "span",
        { staticClass: "pr-2 inline-block" },
        [
          _vm.quote.pricing_type !== 1 ? [_vm._v("最低保証金額")] : _vm._e(),
          _vm._v(" ¥ "),
        ],
        2
      ),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.number",
            value: _vm.yenValue,
            expression: "yenValue",
            modifiers: { number: true },
          },
        ],
        staticClass: "input_text h-10 border border-brown-grey w-1/2",
        attrs: { type: "number" },
        domProps: { value: _vm.yenValue },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.yenValue = _vm._n($event.target.value)
          },
          blur: function ($event) {
            return _vm.$forceUpdate()
          },
        },
      }),
      _c("span", [_vm._v("（税別）")]),
    ]),
    _c("p", [
      _vm._v(
        " " +
          _vm._s(_vm.explanationText) +
          " " +
          _vm._s(_vm.providerPrice) +
          " （税別、販売手数料別） "
      ),
    ]),
    _c("p", { staticClass: "text-xs pb-2" }, [
      _vm._v(
        " ※販売手数料は " + _vm._s(_vm.filminationPrice) + " となります。 "
      ),
    ]),
    _c(
      "button",
      {
        staticClass: "w-full px-10 md:px-4 py-2 bg-base-yellow font-bold mb-2",
        on: {
          click: function ($event) {
            return _vm.offerCounter()
          },
        },
      },
      [_vm._v(" カウンターオファーをする ")]
    ),
    _c(
      "button",
      {
        staticClass: "w-full px-10 md:px-4 py-2 bg-base-yellow font-bold mb-2",
        on: {
          click: function ($event) {
            return _vm.resetCounter()
          },
        },
      },
      [_vm._v(" 提案金額に戻す ")]
    ),
    _c(
      "button",
      {
        staticClass: "w-full px-10 md:px-4 py-2 bg-base-yellow font-bold mb-2",
        on: {
          click: function ($event) {
            return _vm.cancelCounter()
          },
        },
      },
      [_vm._v("キャンセル")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg-light-grey text-left mb-2" },
    [
      _c(
        "div",
        {
          staticClass:
            "grid grid-cols-12 gap-x-6 p-2 border-dashed border-b border-brown-grey",
        },
        [
          _c("div", { staticClass: "col-start-1 col-span-3" }, [
            !_vm.isFilled && !_vm.isUpdate
              ? _c("img", {
                  staticClass: "w-6 h-6 float-left",
                  attrs: { src: require("@/assets/image/alert_icon.svg") },
                })
              : _vm.isAgreed && _vm.isFilled
              ? _c("img", {
                  staticClass: "w-6 h-6 float-left",
                  attrs: { src: require("@/assets/image/ok_icon.svg") },
                })
              : !_vm.isAgreed && _vm.isFilled
              ? _c("img", {
                  staticClass: "w-6 h-6 float-left",
                  attrs: { src: require("@/assets/image/ng_icon.svg") },
                })
              : _vm._e(),
            _c("div", { staticClass: "ml-8" }, [_vm._v(_vm._s(_vm.title))]),
          ]),
          _c("p", { staticClass: "col-span-7" }, [_vm._v(_vm._s(_vm.outline))]),
          _c(
            "div",
            { staticClass: "col-start-11 col-span-2 text-right" },
            [
              _vm.original && _vm.requireResponse
                ? [
                    _c(
                      "button",
                      {
                        staticClass: "inline align-middle",
                        on: { click: _vm.agreeToTerms },
                      },
                      [
                        _vm.isAgreed && !_vm.isEmpty
                          ? _c("img", {
                              staticClass: "w-6 h-6 mr-2",
                              attrs: {
                                src: require("@/assets/image/ok_square_icon.svg"),
                              },
                            })
                          : _c("img", {
                              staticClass: "w-6 h-6 mr-2",
                              attrs: {
                                src: require("@/assets/image/ok_square_off_icon.svg"),
                              },
                            }),
                      ]
                    ),
                    _vm.hasButtons
                      ? _c(
                          "button",
                          {
                            staticClass: "inline align-middle",
                            on: { click: _vm.openModal },
                          },
                          [
                            !_vm.isAgreed && _vm.hasInput
                              ? _c("img", {
                                  staticClass: "w-6 h-6 mr-2",
                                  attrs: {
                                    src: require("@/assets/image/ng_square_icon.svg"),
                                  },
                                })
                              : _c("img", {
                                  staticClass: "w-6 h-6 mr-2",
                                  attrs: {
                                    src: require("@/assets/image/ng_square_off_icon.svg"),
                                  },
                                }),
                          ]
                        )
                      : [
                          !_vm.isAgreed && _vm.hasInput
                            ? _c("img", {
                                staticClass: "w-6 h-6 mr-2 inline",
                                attrs: {
                                  src: require("@/assets/image/ng_square_icon.svg"),
                                },
                              })
                            : _c("img", {
                                staticClass: "w-6 h-6 mr-2 inline",
                                attrs: {
                                  src: require("@/assets/image/ng_square_off_icon.svg"),
                                },
                              }),
                        ],
                  ]
                : _vm._e(),
              _vm.hasSlotDefault
                ? _c("button", { staticClass: "inline align-middle" }, [
                    !(
                      (!_vm.isFilled && !_vm.isUpdate) ||
                      !_vm.isAgreed ||
                      _vm.isOpen
                    )
                      ? _c("img", {
                          staticClass: "w-6 h-6",
                          attrs: {
                            src: require("@/assets/image/open_square_icon.svg"),
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              _vm.isOpen = true
                            },
                          },
                        })
                      : _c("img", {
                          staticClass: "w-6 h-6",
                          attrs: {
                            src: require("@/assets/image/close_square_icon.svg"),
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              _vm.isOpen = false
                            },
                          },
                        }),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      (!_vm.isFilled && !_vm.isUpdate) || !_vm.isAgreed || _vm.isOpen
        ? [
            _c(
              "div",
              { staticClass: "p-2" },
              [
                _vm._t("default", null, {
                  internal: _vm.internal,
                  internalSet: _vm.internalSet,
                }),
                _vm.hasButtons
                  ? [
                      _c(
                        "div",
                        { staticClass: "pt-2 w-full flex justify-end" },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "px-10 lg:px-6 md:px-4 py-2 ml-3 bg-base-yellow disabled:bg-very-light-grey font-bold",
                              attrs: {
                                disabled: !(!_vm.isFilled || _vm.hasChanged),
                              },
                              on: { click: _vm.agreeToTerms },
                            },
                            [_vm._v(" 承認 ")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass:
                                "px-10 lg:px-6 md:px-4 py-2 ml-3 bg-base-yellow disabled:bg-very-light-grey font-bold",
                              attrs: {
                                disabled: !(!_vm.isFilled || _vm.hasChanged),
                              },
                              on: { click: _vm.openModal },
                            },
                            [_vm._v(" 拒否 ")]
                          ),
                          _vm.isFilled
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "px-10 lg:px-6 md:px-4 py-2 ml-3 bg-base-yellow font-bold",
                                  on: { click: _vm.openModal },
                                },
                                [_vm._v(" 変更 ")]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  : _vm.requireResponse
                  ? [
                      _c(
                        "div",
                        { staticClass: "pt-2 w-1/3 flex justify-start" },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "px-10 lg:px-6 md:px-4 py-2 bg-base-yellow font-bold disabled:bg-very-light-grey",
                              attrs: {
                                disabled: !(!_vm.isFilled || _vm.hasChanged),
                              },
                              on: { click: _vm.fillRest },
                            },
                            [_vm._v(" 上記条件で承認する ")]
                          ),
                        ]
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm.hasButtons && _vm.requireResponse
        ? _c(
            "NoticeLinkModal",
            {
              attrs: { modalName: _vm.modalName },
              on: { "before-close": _vm.modalIsClosing },
            },
            [
              _vm._t("modal", null, {
                internal: _vm.internal,
                original: _vm.original,
                internalSet: _vm.internalSet,
                accept: _vm.fillRest,
                cancel: _vm.resetInternal,
                modalName: _vm.modalName,
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
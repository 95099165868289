var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "group cursor-pointer",
      on: {
        click: function ($event) {
          return _vm.copySlotToClipboard()
        },
        mouseover: function ($event) {
          _vm.isHover = true
        },
        mouseleave: function ($event) {
          _vm.isHover = false
        },
      },
    },
    [
      _c(
        "span",
        {
          staticClass: "border-2 border-dashed rounded p-1",
          class: _vm.isHovering ? "border-gray-400" : "border-transparent",
        },
        [
          _vm._t("default"),
          _c(
            "span",
            {
              staticClass: "bg-gray-100 ml-1 p-1 -m-1 rounded-r font-bold",
              class: _vm.isHovering ? "visible" : "invisible",
            },
            [_vm._v(_vm._s(_vm.copyString))]
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }